/**
 * Defines the role of the user looking at the transaction.
 * In tracking app, when delivering, role is set to SENDER
 * In tracking app, when receiving a remote request, server returns role as RECIPIENT
 * In history pages, server returns the role that the facility/org
 * looking at the transaction plays in the transaction
 */
export var TransactionRole;
(function (TransactionRole) {
    TransactionRole["SENDER"] = "SENDER";
    TransactionRole["RECIPIENT"] = "RECIPIENT";
    TransactionRole["PROCESSOR"] = "PROCESSOR";
})(TransactionRole || (TransactionRole = {}));
export function validateRole(role) {
    if (!(role in TransactionRole)) {
        throw new Error(`Invalid role: ${role}`);
    }
    return role;
}
export function isSender(role) {
    return role === TransactionRole.SENDER;
}
export function isRecipient(role) {
    return role === TransactionRole.RECIPIENT;
}
export function isProcessor(role) {
    return role === TransactionRole.PROCESSOR;
}
