import { Request } from './Request';
import { EmpowerLocation, Manifest } from './Manifest';
import { calculateProductionLoss } from '../materials/ProductionLoss';
import { ACCEPTED, CANCELED, CANCELLED, COMPLETED, INITIATED, REJECTED, REQUESTED, REVERTED, STATES } from './TxState';
import { CURRENCIES } from './Currency';
import { MaterialAmount, WEIGHT } from '../materials/MaterialAmount';
import { isProcessor, isRecipient, isSender, validateRole } from './TransactionRole';
import { isDeposit, isOnsiteTransport, isProcess, isRemoteTransport, isUnconfirmed, isUnconfirmedDelivery, isUnconfirmedReception, validateType, isReversion } from './TransactionType';
/** @typedef {'delivery' | 'reception'} Side */
export const SIDES = {
    DELIVERY: /** @type {Side} */ ('delivery'),
    RECEPTION: /** @type {Side} */ ('reception')
};
export class Transaction {
    /**
       * @param {Object} standardData
       * @returns {Transaction}
       */
    constructor(standardData) {
        this.id = standardData.id;
        /** @type {string} **/
        this.initiatedTime = new Date(standardData.initiatedTime).toISOString();
        this.state = standardData.state;
        this.type = standardData.type;
        this.role = standardData.role;
        this.delivery = standardData.delivery ? new Manifest(standardData.delivery) : undefined;
        this.reception = standardData.reception ? new Manifest(standardData.reception) : undefined;
        this.request = standardData.request ? new Request(standardData.request) : undefined;
        this.manualDeliverer = standardData.manualDeliverer;
        this.manualRecipient = standardData.manualRecipient;
        this.depositId = standardData.depositId;
        /** @type {string|undefined} optional completed time **/
        this.completedTime = standardData.completedTime ? new Date(standardData.completedTime).toISOString() : undefined;
        /** @type {Currency|undefined} **/
        this.currency = standardData.currency;
    }
    /** Creates an empty transaction
     * @param facilityId
     * @param {TxType} txType
     * @param {string} role
    **/
    static empty(facilityId, txType, role) {
        return new Transaction({
            initiatedTime: new Date().getUTCMilliseconds(),
            state: INITIATED,
            type: txType,
            role: validateRole(role),
            delivery: Manifest.empty(facilityId) // Because initiated -> delivery above.
        });
    }
    /**
     * @param {Object} response
     * @param {PreferredUnits} preferredUnits
     * @returns {Transaction}
     */
    static fromApiResponse(response, preferredUnits) {
        const validatedTxData = {
            id: response.transactionId,
            initiatedTime: new Date(response.transactionData.initiatedDateTime).toISOString(),
            state: this.validateState(response.status),
            type: validateType(response.type),
            role: validateRole(response.role)
        };
        if (this.hasEmpowerDeliverer(response)) {
            validatedTxData.delivery = this.validateManifest(response, preferredUnits, SIDES.DELIVERY);
        }
        else {
            validatedTxData.manualDeliverer = response.transactionData.manualDeliverer;
        }
        if (this.hasEmpowerRecipient(response)) {
            validatedTxData.reception = this.validateManifest(response, preferredUnits, SIDES.RECEPTION);
        }
        else {
            validatedTxData.manualRecipient = response.transactionData.manualRecipient;
        }
        if (this.hasRequest(response)) {
            validatedTxData.request = this.validateRequest(response);
        }
        if (this.hasDeposit(response)) {
            validatedTxData.depositId = response.transactionData.depositId;
        }
        if (this.hasCompletedTime(response)) {
            validatedTxData.completedTime = new Date(response.transactionData.completedDateTime).toISOString();
        }
        if (this.hasCurrency(response)) {
            validatedTxData.currency = this.validateCurrency(response);
        }
        return new Transaction(validatedTxData);
    }
    static validateState(status) {
        if (!STATES.isValid(status)) {
            throw new Error(`Invalid state: ${status}`);
        }
        return status;
    }
    /**
     *
     * @param {Object} response
     * @returns {string}
     */
    static validateCurrency(response) {
        if (!CURRENCIES.isValid(response.transactionData.currency)) {
            throw new Error(`Invalid currency: ${response.transactionData.currency}`);
        }
        return response.transactionData.currency;
    }
    /**
     * @param {Object} txObj
     * @param {PreferredUnits} preferredUnits
     * @param {Side} side
     * @returns {Manifest}
     **/
    static validateManifest(txObj, preferredUnits, side) {
        let wasteAmounts, facilityId, facilityName, organizationName, transactionMetadata;
        if (side === SIDES.DELIVERY) {
            wasteAmounts = txObj.transactionData.delivererWasteAmounts;
            facilityId = txObj.transactionData.delivererFacilityId;
            facilityName = txObj.transactionData.delivererFacilityName;
            organizationName = txObj.transactionData.delivererOrganizationName;
            transactionMetadata = txObj.transactionData.delivererTransactionMetadata;
        }
        else if (side === SIDES.RECEPTION) {
            wasteAmounts = txObj.transactionData.recipientWasteAmounts;
            facilityId = txObj.transactionData.recipientFacilityId;
            facilityName = txObj.transactionData.recipientFacilityName;
            organizationName = txObj.transactionData.recipientOrganizationName;
            transactionMetadata = txObj.transactionData.recipientTransactionMetadata;
        }
        else {
            throw new Error('Invalid transaction side');
        }
        return Manifest.fromApiResponse(wasteAmounts, facilityId, facilityName, organizationName, txObj.transactionData.registeredBy, transactionMetadata || null, preferredUnits);
    }
    /**
       * @param {Object} txObj
       * @returns {Request}
       **/
    static validateRequest(txObj) {
        return Request.fromApiResponse(txObj.requestData);
    }
    static hasCompletedTime(txObj) {
        return !!(txObj.transactionData.completedDateTime);
    }
    /**
     * @param {Object} txObj
     * @returns {Boolean}
     **/
    static hasEmpowerDeliverer(txObj) {
        /**
         * An unconfirmed reception will still have
         * delivererWasteAmounts. Therefore, we use
         * delivererFacilityId to confirm that an
         * Empower facility sent this request.
         **/
        return !!(txObj.transactionData.delivererFacilityId);
    }
    /**
     * @param {Object} txObj
     * @returns {Boolean}
     **/
    static hasEmpowerRecipient(txObj) {
        /**
         * An unconfirmed delivery will still have
         * recipientWasteAmounts. Therefore, we use
         * recipientFacilityId to confirm that an
         * Empower facility sent this request.
         **/
        return !!(txObj.transactionData.recipientFacilityId);
    }
    /**
     * @param {Object} txObj
     * @returns {Boolean}
     **/
    static hasRequest(txObj) {
        return !!(txObj.requestData);
    }
    /**
     * @param {Object} txObj
     * @returns {Boolean}
     **/
    static hasCurrency(txObj) {
        if (txObj.transactionData.currency) {
            return true;
        }
    }
    /**
     * @param {Object} txObj
     * @returns {Boolean}
     **/
    static hasDeposit(txObj) {
        /**
         * An unconfirmed delivery will still have
         * recipientWasteAmounts. Therefore, we use
         * recipientFacilityId to confirm that an
         * Empower facility sent this request.
         **/
        return !!(txObj.transactionData.depositId);
    }
    /**
     * @returns {Transaction}
     */
    clone() {
        const validatedTxData = {
            id: this.id,
            initiatedTime: this.initiatedTime,
            state: this.state,
            type: this.type,
            role: this.role
        };
        if (this.delivery) {
            validatedTxData.delivery = Object.assign(Object.assign({}, this.delivery), { inventoryItems: this.delivery.cloneInventoryItems() });
            validatedTxData.delivery = new Manifest(validatedTxData.delivery);
        }
        if (this.manualDeliverer) {
            validatedTxData.manualDeliverer = this.manualDeliverer;
        }
        if (this.reception) {
            validatedTxData.reception = Object.assign(Object.assign({}, this.reception), { inventoryItems: this.reception.cloneInventoryItems() });
            validatedTxData.reception = new Manifest(validatedTxData.reception);
        }
        if (this.manualRecipient) {
            validatedTxData.manualRecipient = this.manualRecipient;
        }
        if (this.request) {
            validatedTxData.request = new Request(this.request);
        }
        if (this.depositId) {
            validatedTxData.depositId = this.depositId;
        }
        if (this.completedTime) {
            validatedTxData.completedTime = this.completedTime;
        }
        if (this.currency) {
            validatedTxData.currency = this.currency;
        }
        return new Transaction(validatedTxData);
    }
    /**
     * Should only be used on a cloned Transaction.
     * When a delivery is sent for review to a potential recipient,
     * there is no Reception Manifest. This function will
     * initialize a reception manifest for the purpose of edit & save
     * by a user in the UI of our Tracking App.
     *
     * It is dependent on user/facility specific data of the user/facility
     * reviewing the transaction, as well as data provided by the deliverer
     * (inventory items, for example)
     *
     * @param {PreferredUnits} preferredUnits
     * @param {String} facilityId
     * @param {String} facilityName
     * @param {String} orgName
     * @param {String} registeredBy
     * @param {JsonLocation} facilityLocation
     */
    initializeReception(preferredUnits, facilityId, facilityName, orgName, registeredBy, facilityLocation) {
        this.reception = this.generateManifest(preferredUnits, facilityId, facilityName, orgName, registeredBy, facilityLocation);
    }
    /**
     * Prepare delivery -setting facility data
     * @param {PreferredUnits} preferredUnits
     * @param {String} facilityId
     * @param {String} facilityName
     * @param {String} orgName
     * @param {String} registeredBy
     * @param {JsonLocation} facilityLocation
     */
    initializeDelivery(preferredUnits, facilityId, facilityName, orgName, registeredBy, facilityLocation) {
        this.delivery = this.generateManifest(preferredUnits, facilityId, facilityName, orgName, registeredBy, facilityLocation);
        this.request = new Request({
            target: {}
        });
    }
    /**
     * @param {PreferredUnits} preferredUnits
     * @param {String} facilityId
     * @param {String} facilityName
     * @param {String} orgName
     * @param {String} registeredBy
     * @param {JsonLocation} facilityLocation
     */
    generateManifest(preferredUnits, facilityId, facilityName, orgName, registeredBy, facilityLocation) {
        if (!preferredUnits) {
            throw Error(`Preferred units ${preferredUnits} not found `);
        }
        if (this.reception !== undefined) {
            throw Error('Reception is already present');
        }
        const presumptiveMetaData = {
            userSpecifiedTime: new Date().toISOString(),
            files: [],
            location: EmpowerLocation.generateStarterLocation(facilityLocation),
            message: undefined
        };
        const presumptiveReceivedItems = this.delivery.cloneInventoryItems();
        const presumptiveManifestData = {
            facilityId,
            facilityName,
            orgName,
            registeredBy,
            inventoryItems: presumptiveReceivedItems,
            metaData: presumptiveMetaData
        };
        return new Manifest(presumptiveManifestData);
    }
    /**
     * User will have some Reception data already added
     * to the Transaction when the transaction is in
     * discrepancy state. Here we just add what's needed
     * for the user to be able to review/approve the discrepancy transaction.
     * @param {JsonLocation} facilityLocation
     */
    initializePartialReception(facilityLocation) {
        this.reception.location = EmpowerLocation.fromApiResponse(facilityLocation);
        this.reception.userSpecifiedTime = new Date().toISOString();
        if (CANCELLED.equals(this.discrepancyState) || REJECTED.equals(this.discrepancyState)) {
            this.reception.inventoryItems = this.delivery.cloneInventoryItems();
        }
    }
    /**
     * No further action will be taken on the transaction. It's done.
     */
    isDone() {
        return COMPLETED.equals(this.state) || ACCEPTED.equals(this.state) || REJECTED.equals(this.state);
    }
    isInitiatedReceptionWithDiscrepancyState() {
        return INITIATED.equals(this.state) && this.hasDiscrepancy && this.isRemoteDeliveryInRecipientContext();
    }
    isInitiatedReceptionWithReviewedDiscrepancyState() {
        return INITIATED.equals(this.state) &&
            this.hasDiscrepancy &&
            this.isRemoteDeliveryInRecipientContext() &&
            !this.hasRequestedDiscrepancy();
    }
    isInitiated() {
        return INITIATED.equals(this.state);
    }
    isCanceled() {
        return CANCELED.equals(this.state);
    }
    isAccepted() {
        return ACCEPTED.equals(this.state);
    }
    isRejected() {
        return REJECTED.equals(this.state);
    }
    /**
     * @returns {boolean}
     */
    isCompleted() {
        return COMPLETED.equals(this.state);
    }
    /** @returns {Boolean} **/
    get hasDiscrepancy() {
        return !!this.request && !!this.request.discrepancyState;
    }
    /**
       * True if delivery tx
       * @returns {boolean}
       */
    isDelivery() {
        return isUnconfirmedDelivery(this.type) ||
            this.isRemoteDeliveryInDelivererContext() ||
            this.isOnsiteDeliveryInDelivererContext();
    }
    hasInitiatedDiscrepancy() {
        return INITIATED.equals(this.discrepancyState);
    }
    hasAcceptedDiscrepancy() {
        return ACCEPTED.equals(this.discrepancyState);
    }
    hasRejectedDiscrepancy() {
        return REJECTED.equals(this.discrepancyState);
    }
    hasCancelledDiscrepancy() {
        return CANCELLED.equals(this.discrepancyState);
    }
    hasRequestedDiscrepancy() {
        return REQUESTED.equals(this.discrepancyState);
    }
    isInitiatedRemoteDeliveryInDelivererContext() {
        return this.isRemoteDeliveryInDelivererContext() && INITIATED.equals(this.state);
    }
    isCompletedRemoteDeliveryInDelivererContext() {
        return this.isRemoteDeliveryInDelivererContext() && ACCEPTED.equals(this.state);
    }
    isOnsiteDeliveryInDelivererContext() {
        return isOnsiteTransport(this.type) && isSender(this.role);
    }
    isOnsiteReceptionInRecipientContext() {
        return isOnsiteTransport(this.type) && isRecipient(this.role);
    }
    isCompletedOnsiteDeliveryInDelivererContext() {
        return this.isOnsiteDeliveryInDelivererContext() && ACCEPTED.equals(this.state);
    }
    isCompletedOnsiteOrRemoteDeliveryInDelivererContext() {
        return this.isOnsiteDeliveryInDelivererContext() && ACCEPTED.equals(this.state);
    }
    isRemoteDeliveryInDelivererContext() {
        return isRemoteTransport(this.type) && isSender(this.role);
    }
    isRemoteDeliveryInRecipientContext() {
        return isRemoteTransport(this.type) && isRecipient(this.role);
    }
    isRejectedRemoteDeliveryInDelivererContext() {
        return this.isRemoteDeliveryInRecipientContext() && REJECTED.equals(this.state);
    }
    isCanceledRemoteDeliveryInDelivererContext() {
        return this.isRemoteDeliveryInRecipientContext() && CANCELED.equals(this.state);
    }
    isReception() {
        return isUnconfirmedReception(this.type) ||
            isDeposit(this.type) ||
            this.isOnsiteReceptionInRecipientContext() ||
            this.isRemoteDeliveryInRecipientContext();
    }
    /** @returns {boolean} true if this tx is a process transaction **/
    isProcess() {
        return isProcess(this.type);
    }
    isInitiatedRemoteDeliveryInRecipientContext() {
        return this.isRemoteDeliveryInRecipientContext() && INITIATED.equals(this.state);
    }
    /** @return {string} facility ID for the local facility **/
    getLocalFacilityId() {
        return this.manifestForSide.facilityId;
    }
    /**
       * @returns {boolean}
       */
    isProcessToMaterials() {
        if (isProcess(this.type)) {
            return this.reception.containsMassBalance();
        }
        return false;
    }
    /**
       * @returns {boolean}
       */
    isProcessToDigitalTwins() {
        if (isProcess(this.type)) {
            return this.reception.containsDigitalTwin();
        }
        return false;
    }
    /**
     * Formatted string representation of the remote end, as recipient or deliverer depending on direction
     * @return {string}
     */
    get remoteEntity() {
        return this.isDelivery() ? this.recipient : this.deliverer;
    }
    /**
       * Formatted string representation of the deliverer
       * @return {string}
       **/
    get deliverer() {
        var _a;
        if (this.delivery) {
            return this.orgAndPossiblyFacility(this.delivery.orgName, (_a = this.delivery) === null || _a === void 0 ? void 0 : _a.facilityName);
        }
        else {
            return this.manualDeliverer;
        }
    }
    /**
       * Formatted string representation of the recipient
       * @return {string}
       **/
    get recipient() {
        var _a;
        if (this.reception) {
            return this.orgAndPossiblyFacility(this.reception.orgName, this.reception.facilityName);
        }
        else if ((_a = this.request) === null || _a === void 0 ? void 0 : _a.target) {
            // valid for initiated outgoing tx
            return this.orgAndPossiblyFacility(this.request.target.orgName, this.request.target.facilityName);
        }
        else {
            return this.manualRecipient
                ? this.manualRecipient
                : this.orgAndPossiblyFacility(this.delivery.orgName, this.delivery.facilityName);
        }
    }
    /**
     * shorthand for making consistent remote party strings: org [, facility] if facility is present
     * @param {String} orgName
     * @param {String | undefined} facilityName
     * @returns {String}
     */
    orgAndPossiblyFacility(orgName, facilityName) {
        return facilityName
            ? `${orgName}, ${facilityName}`
            : `${orgName}`;
    }
    get target() {
        var _a, _b, _c, _d, _e;
        return ((_a = this.request.target) === null || _a === void 0 ? void 0 : _a.orgName)
            ? this.orgAndPossiblyFacility((_b = this.request.target) === null || _b === void 0 ? void 0 : _b.orgName, (_c = this.request.target) === null || _c === void 0 ? void 0 : _c.facilityName)
            : this.orgAndPossiblyFacility((_d = this.reception) === null || _d === void 0 ? void 0 : _d.orgName, (_e = this.reception) === null || _e === void 0 ? void 0 : _e.facilityName);
    }
    /** @returns {string|undefined} this user's manually specified time, as ISO-8601 string **/
    get userSelectedTimeInContext() {
        var _a, _b, _c, _d, _e, _f;
        if (this.isDelivery() && ((_a = this.delivery) === null || _a === void 0 ? void 0 : _a.userSpecifiedTime)) {
            return this.delivery.userSpecifiedTime;
        }
        if (this.isReception() && ((_b = this.reception) === null || _b === void 0 ? void 0 : _b.userSpecifiedTime)) {
            return this.reception.userSpecifiedTime;
        }
        // An initiated delivery will not have a user specified time on the reception
        if (this.isReception() && !((_c = this.reception) === null || _c === void 0 ? void 0 : _c.userSpecifiedTime) && this.delivery) {
            return this.delivery.userSpecifiedTime;
        }
        if (isProcess(this.type) && ((_d = this.reception) === null || _d === void 0 ? void 0 : _d.userSpecifiedTime)) {
            return (_f = (_e = this.reception) === null || _e === void 0 ? void 0 : _e.metaData) === null || _f === void 0 ? void 0 : _f.userSpecifiedTime;
        }
        return undefined;
    }
    /**
       * SYSTEM REGISTERED TIME IS DEPENDENT ON THE PARTY
       * VIEWING THE TRANSACTION.
       * THERE IS NOT ONE SYSTEM REGISTERED TIME.
       * We should consider modifying the backend to
       * make this more clear for the front end.
       * @returns {string} with ISO-8601 format
       */
    get systemRegisteredTimeInContext() {
        /**
         * If it's an incomplete remote delivery, show initiated time
         */
        if (this.isRemoteDeliveryInDelivererContext() && !this.isCompleted()) {
            return this.initiatedTime;
        }
        return this.completedTime || this.initiatedTime;
    }
    /**
       * Returns the correct location metadata for the tx
       * @returns EmpowerLocation
       */
    get location() {
        if (this.isDelivery()) {
            return this.delivery.metaData.location;
        }
        if (this.isReception()) {
            return this.reception.metaData.location;
        }
        if (isProcess(this.type)) {
            return this.reception.metaData.location;
        }
    }
    /**
       *
       * @returns {Array<EmpowerFile>}
       */
    get files() {
        var _a, _b, _c;
        if (this.isDelivery() && this.delivery) {
            return (_a = this.delivery.metaData) === null || _a === void 0 ? void 0 : _a.files;
        }
        if (this.isReception() && this.reception) {
            return (_b = this.reception.metaData) === null || _b === void 0 ? void 0 : _b.files;
        }
        if (isProcess(this.type) && this.reception) {
            return (_c = this.reception.metaData) === null || _c === void 0 ? void 0 : _c.files;
        }
    }
    /**
       * Turns the role in the transaction into a human friendly format
       * @returns {string}
       */
    get readableTxRole() {
        if (isRecipient(this.role)) {
            return 'Reception';
        }
        if (isSender(this.role)) {
            return 'Delivery';
        }
        if (isProcessor(this.role)) {
            return 'Process';
        }
    }
    /**
       * Turns the details of type in the transaction into a human friendly format
       * E.g. UNCONFIRMED_DELIVERY = Unconfirmed
       * @returns {string}
       */
    get readableTxSubType() {
        if (isRemoteTransport(this.type)) {
            return 'Remote';
        }
        if (isOnsiteTransport(this.type)) {
            return 'On Site';
        }
        if (isUnconfirmed(this.type)) {
            return 'Unconfirmed';
        }
        if (isProcess(this.type)) {
            return ''; // there is no subtype for process
        }
        return this.type; // Shouldn't happen
    }
    get readableState() {
        return this.state.charAt(0).toUpperCase() + this.state.toLowerCase().slice(1);
    }
    /**
       * Return the user who registered the tx in their context
       * @returns {string}
       */
    get registeredBy() {
        var _a, _b;
        if (this.isDelivery()) {
            return this.delivery.registeredBy;
        }
        if (this.isReception()) {
            return (_a = this.reception) === null || _a === void 0 ? void 0 : _a.registeredBy;
        }
        if (isProcess(this.type)) {
            return (_b = this.reception) === null || _b === void 0 ? void 0 : _b.registeredBy;
        }
    }
    /**
       * Only returns the inventory items "in context". We may want to consider removing this type of logic.
       * @returns InventoryItem[]
       */
    get inventoryItemsForSide() {
        return this.manifestForSide.inventoryItems;
    }
    /** @returns {Manifest} **/
    get manifestForSide() {
        if (this.isDelivery() || this.isRejected() || this.isInitiated() || this.isCanceled()) {
            return this.delivery;
        }
        if (this.isReception()) {
            return this.reception;
        }
        if (this.isInitiated()) {
            return this.delivery;
        }
        if (isProcess(this.type)) {
            /** returns the same as isReception, but more clear that it's a Process tx */
            return this.reception;
        }
        return null;
    }
    /** @returns {Manifest} **/
    get manifestForOppositeSide() {
        return this.manifestForSide === this.delivery ? this.reception : this.delivery;
    }
    /**
     * Returns the materials with originalAmount attribute if edited and transaction is in discrepancy
     * @returns {Array<InventoryItem>}
     */
    get materialsWithOriginalAmount() {
        var _a, _b, _c;
        if (isUnconfirmedReception(this.type) || !this.hasDiscrepancy || ((_a = this.reception.inventoryItems) === null || _a === void 0 ? void 0 : _a.length) < 1) {
            if (this.isDelivery() || isProcess(this.type)) {
                return this.delivery.inventoryItems;
            }
            else {
                return ((_c = (_b = this.reception) === null || _b === void 0 ? void 0 : _b.inventoryItems) === null || _c === void 0 ? void 0 : _c.length) > 0 ? this.reception.inventoryItems : this.delivery.inventoryItems;
            }
        }
        const materials = this.reception.inventoryItems;
        const originalMaterials = this.delivery.inventoryItems;
        return materials
            .map((waste, index) => {
            const original = originalMaterials[index];
            if (!!original && !original.massBalance.amount.equals(waste.massBalance.amount)) {
                waste.massBalance.originalAmount = original.massBalance.amount;
                if (!!waste.massBalance.payment &&
                    waste.massBalance.payment !== original.massBalance.payment) {
                    waste.massBalance.payment.originalAmount = original.massBalance.payment.amount;
                    waste.massBalance.payment.originalPricePerUnit = original.massBalance.payment.pricePerUnit;
                }
            }
            return waste;
        });
    }
    get deliveredDigitalTwinsName() {
        if (!this.delivery.containsDigitalTwin()) {
            return null;
        }
        return this.delivery.inventoryItems[0].digitalTwin.name;
    }
    /** Used when processing to digital twins **/
    get receivedDigitalTwinsName() {
        if (!this.reception.containsDigitalTwin()) {
            return null;
        }
        return this.reception.inventoryItems[0].digitalTwin.name;
    }
    get deliveredDigitalTwinsAmount() {
        if (!this.delivery.containsDigitalTwin()) {
            return 0;
        }
        return this.delivery.inventoryItems[0].digitalTwin.amount;
    }
    /** Used when processing to digital twins **/
    get receivedDigitalTwinsAmount() {
        if (!this.reception.containsDigitalTwin()) {
            return 0;
        }
        return this.reception.inventoryItems[0].digitalTwin.amount;
    }
    /**
       * Which facility does this tx belong to
       * @returns String
       */
    getFacilityInContext() {
        if (this.isDelivery()) {
            return this.delivery.facilityName;
        }
        else {
            return this.reception.facilityName;
        }
    }
    get facilityIdsInvolvedInTransaction() {
        var _a, _b;
        const facIds = [];
        if ((_a = this.delivery) === null || _a === void 0 ? void 0 : _a.facilityId) {
            facIds.push(this.delivery.facilityId);
        }
        if ((_b = this.reception) === null || _b === void 0 ? void 0 : _b.facilityId) {
            facIds.push(this.reception.facilityId);
        }
        return facIds;
    }
    /**
       * Calculates the production loss for a process tx
       * @param {PreferredUnits} preferredUnits
       * @returns {MaterialAmount}
       */
    getProductionLoss(preferredUnits) {
        if (!isProcess(this.type)) {
            throw Error(`Cannot calculate production loss for transaction: ${this}`);
        }
        const inputAmounts = this.delivery.inventoryItems.map(el => el.amount);
        if (this.containsDigitalTwin()) {
            return this.calculateProductionLossForDigitalTwins(preferredUnits, inputAmounts);
        }
        return this.calculateProductionLossForMassBalances(preferredUnits, inputAmounts);
    }
    /**
     * @param {PreferredUnits} preferredUnits
     * @param {Array<MaterialAmount>} inputAmounts
     * @returns {MaterialAmount}
     */
    calculateProductionLossForMassBalances(preferredUnits, inputAmounts) {
        /**
         * Keeping the consistency of calculateProductionLoss(),
         * which always returns a MaterialAmount.
         */
        if (!this.areReceptionAndDeliveryMagnitudesTheSame()) {
            return new MaterialAmount(0, WEIGHT, preferredUnits);
        }
        const outputAmounts = this.reception.inventoryItems.map(el => el.amount);
        return calculateProductionLoss(inputAmounts, outputAmounts, preferredUnits);
    }
    /**
     * @param {PreferredUnits} preferredUnits
     * @param {Array<MaterialAmount>} inputAmounts
     * @returns {MaterialAmount}
     */
    calculateProductionLossForDigitalTwins(preferredUnits, inputAmounts) {
        const outputAmounts = this.reception.inventoryItems.map(item => {
            if (!item.type.equals('DIGITAL_TWIN')) {
                throw Error(`Inventory Item type not found for item: ${item} `);
            }
            const digitalTwin = item.digitalTwin;
            if (digitalTwin.isInventorySameMagnitude()) {
                const materialAmount = digitalTwin.inventory.reduce((acc, curr) => { return curr.amount.plus(acc); }, 0);
                return materialAmount.times(digitalTwin.amount);
            }
            else {
                return undefined;
            }
        });
        /**
         * Keeping the consistency of calculateProductionLoss(),
         * which always returns a MaterialAmount.
         */
        if (outputAmounts.some(materialAmount => !(materialAmount instanceof MaterialAmount))) {
            return new MaterialAmount(0, WEIGHT, preferredUnits);
        }
        return calculateProductionLoss(inputAmounts, outputAmounts, preferredUnits);
    }
    areReceptionAndDeliveryMagnitudesTheSame() {
        if (!this.delivery.areMagnitudesTheSame() || !this.reception.areMagnitudesTheSame()) {
            return false;
        }
        const deliveryMags = this.delivery.getMagnitudes();
        const receptionMags = this.reception.getMagnitudes();
        /**
             * Since by this point we've already determined
             * all mags are the same on each side,
             * we can just compare the first element in each set of mags
             */
        return deliveryMags[0].equals(receptionMags[0]);
    }
    /**
       * Checks if the tx contains a digital twin
       * @returns Boolean
       */
    containsDigitalTwin() {
        var _a;
        let digitalTwinsInDelivery = false;
        let digitalTwinsInReception = false;
        if (this.delivery) {
            digitalTwinsInDelivery = this.delivery.containsDigitalTwin();
        }
        if ((_a = this.reception) === null || _a === void 0 ? void 0 : _a.inventoryItems.length) {
            digitalTwinsInReception = this.reception.containsDigitalTwin();
        }
        return digitalTwinsInDelivery || digitalTwinsInReception;
    }
    /**
       * For Mass Balances, returns a string of
       * Material types. For each mb inventory item,
       * grab the value for 'plasticType', and flatten.
       *
       * For Digital Twins, returns digital twin name.
       * @param {InventoryItem[]} items
       * @returns {String}
       */
    getReadableInventoryItemNames(items) {
        let readableTxItemNames = '';
        if (this.containsDigitalTwin()) {
            readableTxItemNames = items.map(item => {
                return item.digitalTwin.name;
            }).join(); // no special transformation here, only ever 1 dt delivered at a time today.
            return readableTxItemNames;
        }
        else {
            const names = items.map(item => {
                const materialName = item.massBalance.massBalanceDefinition.plasticType;
                if (materialName == null) {
                    return Error('Material Type not found in Mass Balance Definition');
                }
                else {
                    return materialName;
                }
            });
            const length = names.length;
            if (length === 1) {
                readableTxItemNames = names[0];
            }
            else if (length === 2) {
                readableTxItemNames = `${names[0]} and ${names[1]}`;
            }
            else if (length >= 3) {
                const lastItem = names[length - 1];
                const otherItems = names.slice(0, length - 1).join(', ');
                readableTxItemNames = `${otherItems}, and ${lastItem}`;
            }
            return readableTxItemNames;
        }
    }
    /**
       * Returns a list of the delivery inventory items
       * @returns InventoryItem[]
       */
    getInput() {
        return this.delivery.inventoryItems;
    }
    /**
       * Returns a list of the reception inventory items
       * @returns InventoryItem[]
       */
    getOutput() {
        return this.reception.inventoryItems;
    }
    isEditable() {
        return isUnconfirmed(this.type) && !this.isReverted() && !this.isUnconfirmedDeliveryOfDigitalTwins();
    }
    isUnconfirmedDeliveryOfDigitalTwins() {
        return isUnconfirmed(this.type) && this.isDelivery() && this.containsDigitalTwin();
    }
    isRevertable() {
        return (!this.isReverted() && !isDeposit(this.type) && (isUnconfirmed(this.type) || this.isProcessToMaterials()));
    }
    isReverted() {
        return REVERTED.equals(this.state);
    }
    /** @returns {boolean} true if this transaction is a reversion of another transaction**/
    isReversion() {
        return isReversion(this.type);
    }
    /** @returns {string|undefined} **/
    get discrepancyState() {
        var _a;
        return (_a = this.request) === null || _a === void 0 ? void 0 : _a.discrepancyState;
    }
    get requestCode() {
        var _a;
        return (_a = this.request) === null || _a === void 0 ? void 0 : _a.code;
    }
    /**
     * Return total payment for all inventory items
     * @returns {Payment}
     */
    get totalPayment() {
        var _a;
        return (_a = this.manifestForSide) === null || _a === void 0 ? void 0 : _a.totalPayment;
    }
    get totalPaymentForOppositeSide() {
        var _a;
        return (_a = this.manifestForOppositeSide) === null || _a === void 0 ? void 0 : _a.totalPayment;
    }
    /**
     * Expected JSON payload for /updateTransaction API call
     * @param {string} transactionId
     * @param {string} facilityId
     * @param {TxType} type
     * @param {Array<EmpowerFile>} files
     * @param {Array<InventoryItem>} inventoryItems
     * @param {number} latitude
     * @param {number} longitude
     * @param {string} userSpecifiedTime
     * @param {string|undefined} message
     * @param {string} manualParty
     * @param {string|undefined} currency
     */
    toUpdateJson(transactionId, facilityId, type, files, inventoryItems, latitude, longitude, userSpecifiedTime, message, manualParty, currency) {
        const updateJson = {
            transactionId,
            facilityId,
            files,
            inventoryItems: inventoryItems.map(item => item.toJson()),
            latitude,
            longitude,
            manualDateTime: userSpecifiedTime,
            message,
            currency
        };
        let manualPartyKey;
        if (type === 'UNCONFIRMED_RECEPTION') {
            manualPartyKey = 'manualDeliverer';
        }
        else if (type === 'UNCONFIRMED_DELIVERY') {
            manualPartyKey = 'manualRecipient';
        }
        updateJson[manualPartyKey] = manualParty;
        return updateJson;
    }
    /**
     * Expected JSON payload for /updateDeposit API call
     * @param {string} depositId
     * @param {Array<EmpowerFile>} files
     * @param {Array<InventoryItem>} inventoryItems
     * @param {number} latitude
     * @param {number} longitude
     * @param {string} userSpecifiedTime
     * @param {string|undefined} message
     * @param {string} manualDeliverer
     * @param {string|undefined} currency
     */
    toUpdateDepositJson(depositId, files, inventoryItems, latitude, longitude, userSpecifiedTime, message, manualDeliverer, currency) {
        const items = inventoryItems.map(item => item.toJson());
        const depositWasteItem = items[0];
        let payment;
        if (items[0].payment) {
            payment = items[0].payment;
            payment.currency = currency;
            payment.pricePerKg = payment.pricePerUnit;
            delete payment.pricePerUnit;
        }
        const updateJson = {
            depositId,
            files,
            massBalanceDefinition: depositWasteItem.massBalanceDefinition,
            amount: depositWasteItem.amount,
            latitude,
            longitude,
            manualDateTime: userSpecifiedTime,
            message,
            manualDeliverer,
            payment
        };
        return updateJson;
    }
    /**
     * Checks if the specified side has a manifest.
     * @param {Side} side
     */
    hasManifest(side) {
        switch (side) {
            case SIDES.DELIVERY:
                return this.delivery instanceof Manifest;
            case SIDES.RECEPTION:
                return this.reception instanceof Manifest;
            default:
                console.warn(`Unexpected side: ${side}`);
                return false;
        }
    }
}
