import axios from 'axios'
import {apiService} from "@/main";
import { MaterialAmount } from '@empower-platform/tracking-frontend-shared-types-js/build/materials/MaterialAmount';

export default class {
    wasteEndpoint = process.env.VUE_APP_WASTE_API_BASE_URL + '/api/v1/waste';
    userService;
    facilityService;
    facilityId;

    constructor(userService, facilityService) {
        this.userService = userService;
        this.facilityService = facilityService;
        this.setFacilityId();
    }

    setFacilityId() {
        this.facilityId = this.facilityService.getSelectedFacilityId();
    }

    async getAllTransactionRequests() {
        return await apiService.call('GET', `${this.wasteEndpoint}/transactions/requests?facilityId=${this.facilityId}`);
    }

    async getInventory() {
        return await apiService.call('GET', `${this.wasteEndpoint}/transactions/mass-balances?facilityId=${this.facilityId}`);
    }

    async getFacilityHistory(queryParams) {
        return await apiService.call('GET', `${this.wasteEndpoint}/transactions/any?facilityId=${this.facilityId}&${queryParams}`)
    }

    createUnverifiedReceive(inventoryItems, longitude, latitude, message, manualDateTime, files, manualDeliverer, currency) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/unconfirmed/receptions",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                inventoryItems,
                latitude,
                longitude,
                message,
                manualDateTime,
                files,
                manualDeliverer,
                currency
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            })
    }

    createUnverifiedDeliver(inventoryItems, longitude, latitude, message, manualDateTime, files, manualRecipient, currency) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/unconfirmed/deliveries",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                inventoryItems,
                latitude,
                longitude,
                message,
                manualDateTime,
                files,
                manualRecipient,
                currency
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            })
    }

    createDeliveryTransactionRequest(type, inventoryItems, longitude, latitude, message, manualDateTime, files, targetedOrganization, targetedFacility, currency) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests",
            {
                type: type,
                facilityId: this.facilityService.getSelectedFacilityId(),
                inventoryItems,
                latitude,
                longitude,
                message,
                manualDateTime,
                files,
                targetedOrganization,
                targetedFacility,
                currency
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            }).then(response => response.data)

    }

    getTransactionRequest(requestCode) {
        return axios.get(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "?facilityId=" + this.facilityService.getSelectedFacilityId(),
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            })
            .then(response => response.data)
    }

    autoCancelTransactionRequest(requestCode) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/cancel",
            {
                facilityId: this.facilityService.getSelectedFacilityId()
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            });
    }

    async manualCancelTransactionRequest(requestCode, cancelReason) {
        return await apiService.call('POST', `${this.wasteEndpoint}/transactions/requests/${requestCode}/manualCancel`, {
            facilityId: this.facilityService.getSelectedFacilityId(),
            cancelReason
        });
    }

    rejectTransactionRequest(requestCode, rejectReason) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/reject",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                rejectReason
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            });
    }

    acceptTransactionRequest(requestCode, longitude, latitude, message, manualDateTime, files) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/accept",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                longitude,
                latitude,
                message,
                manualDateTime,
                files,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            });
    }

    requestChangesOnTransactionRequest(requestCode, receivedItems, message, files) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/request-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                receivedItems,
                ...(message && { message: message }),
                files
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            });
    }

    acceptChangesOnTransactionRequest(requestCode, reasonForLoss, message) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/accept-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                missing: reasonForLoss,
                message
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            }
        )
    }

    rejectChangesOnTransactionRequest(requestCode,message) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/reject-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                message
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            }
        )
    }

    cancelChangesOnTransactionRequest(requestCode, message) {
        return axios.post(
            process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/requests/" + requestCode + "/cancel-changes",
            {
                facilityId: this.facilityService.getSelectedFacilityId(),
                message: message,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + this.userService.token
                }
            }
        );
    }

    getBalanceName(massBalanceDefinition) {
        if (!massBalanceDefinition) return;
        let balanceName = ""
        for (let [key, value] of Object.entries(massBalanceDefinition)) {
            if (key !== 'processAmount') {
                balanceName = balanceName.concat(`${key.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                    return str.toUpperCase();
                })}: ${value} `)
            }
        }
        return balanceName
    }

    async isSerialNumberInUse(serialNo) {
        return await apiService.call('GET',
            `${this.wasteEndpoint}/transactions/serial-numbers/${serialNo}/check-in-use`)
            .then(response => response.isInUse);
    }
}
